.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  background-color: #0059AC;
  line-height: 1.55555556;
  cursor: pointer;
  color: #fff;
  padding: 8px 40px;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
}
.button:hover,
.button:focus {
  color: #fff;
  background-color: #004c93;
}
.button:active {
  background-color: #003160;
}
.area--one {
  width: 101.25%;
  margin-left: -0.625%;
  display: flex;
  flex-wrap: wrap;
}
.area--one .unit {
  margin-left: 0.61728395%;
  margin-right: 0.61728395%;
}
.area--one .unit--spaceTopReduced {
  margin-top: 12px;
}
.area--one .unit--spaceTopNone {
  margin-top: 0;
}
.area--one .unit--spaceBottomReduced {
  margin-bottom: 12px;
}
.area--one .unit--spaceBottomNone {
  margin-bottom: 0;
}
.area--one .unitOne--1-1 {
  width: 98.7654321%;
}
.area--one .unitOne--1-2 {
  width: 48.7654321%;
}
.area--one .unitTwo {
  width: 98.7654321%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}
.area--one .unitTwo .unit__background,
.area--one .unitTwo .unit__content {
  width: 49.375%;
}
.area--one .unitTwo .unit__content {
  margin-top: 0;
  padding-right: 3.75%;
  box-sizing: border-box;
}
.area--one .unitFour--1-1-indent {
  width: 86.99609883%;
  margin-left: 6.50195059%;
  margin-right: 6.50195059%;
}
.area--one .unitFour--1-4 {
  width: 48.76462939%;
}
.area--one .unitFive {
  width: 98.7654321%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}
.area--one .unitFive--image .unit__background,
.area--one .unitFive--image .unit__content {
  width: 49.375%;
}
.area--one .unitFive--image .unit__content {
  display: flex;
  flex-wrap: wrap;
  align-items: end;
  margin-top: var(--spacePart);
}
.area--one .unitFive--image .unit__content .unit__head,
.area--one .unitFive--image .unit__content .unit__foot {
  display: none;
}
.area--one .unitSix {
  width: 98.7654321%;
}
.area--one .unitSix .part.pict:first-child {
  margin-bottom: 0;
}
/*# sourceMappingURL=./screen-medium.css.map */